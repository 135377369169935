// -------------------- live urls --------------------
export const socketURL = {
  polygon: "wss://polyquickswap.cryptolabdex.com/",
  arbitrum: "wss://arbitrum.cryptolabdex.com",
  binance: "wss://bsc.cryptolabdex.com",
};
const url = "https://node.cryptolabdex.com";

// -------------------- staging urls --------------------
// export const socketURL = {
//   polygon: "wss://websocket-polyquickswapben.mobiloitte.io",
//   arbitrum: "wss://websocket-arbitrumben.mobiloitte.io",
//   binance: "wss://websocket-bscben.mobiloitte.io",
// };

// const url = "https://node-arbbotben.mobiloitte.io";

const user = `${url}/api/v1/user`;
const admin = `${url}/api/v1/admin`;
const Static = `${url}/api/v1/static/`;
const stake = `${url}/api/v1/stake`;
const nft = `${url}/api/v1/nft`;
const Transactions = `${url}/api/v1`;
const socketUser = `${socketURL}/api/v1/user`;
// const socketUserApi = `${socketApiURL}/api/v1/user`;
const updateSubscribe = `${url}/api/v1/updateSubscribe`;

const ApiConfig = {
  // Signup us

  // signUp: `${user}/signup`,

  createNFT: `${nft}/createNFT`,
  listNFT: `${nft}/listNFT`,
  stakeNFT: `${stake}/stakeNFT`,
  stakeList: `${stake}/stakeList`,
  unstakeNFT: `${stake}/unstakeNFT`,

  userUpdateSubscribe: `${updateSubscribe}/userUpdateSubscribe`,
  // contact us

  addContactUs: `${user}/addContactUs`,
  connectWallet: `${user}/connectWallet`,
  storeContractAddress: `${user}/storeContractAddress`,
  recentProfit: `${user}/recentProfits`,
  updateProfile: `${user}/updateProfile`,
  getProfile: `${user}/getProfile`,
  buyToken: `${user}/buyToken`,
  buyTokenList: `${user}/buyTokenList`,
  graph: `${user}/graph`,
  profitanalysis: `${user}/profitanalysis`,
  buySubscriptionplan: `${user}/buySubscriptionplan`,
  dashboard: `${user}/dashboard`,

  // addAutoTradingArbitrage: `${socketUserApi}/addAutoTradingArbitrage`,
  // deleteAutoTradingArbitrage: `${socketUserApi}/deleteAutoTradingArbitrage`,
  // viewAutoTradingArbitrage: `${socketUserApi}/viewAutoTradingArbitrage`,
  // getWalletAddress: `${socketUserApi}/getProfile`,
  // listAutoTradingArbitrage: `${socketUserApi}/listAutoTradingArbitrage`,
  // connectWalletS: `${socketUserApi}/connectWallet`,

  // admin
  listSubscriptionPlan: `${admin}/listSubscriptionPlan`,
  addSubscriptionPlan: `${admin}/addSubscriptionPlan`,
  deleteSubscriptionPlan: `${admin}/deleteSubscriptionPlan`,
  buySubscriptionPlanAllList: `${admin}/buySubscriptionPlanAllList`,
  viewBuySubscriptionPlan: `${admin}/viewBuySubscriptionPlan`,
  viewSubscriptionPlan: `${admin}/viewSubscriptionPlan`,
  listToken: `${admin}/listToken`,
  getTransactions: `${admin}/getTransactions`,

  //USER AUTH
  signup: `${user}/signup`,
  verifyOTP: `${user}/verifyOTP`,
  login: `${user}/login`,
  forgotPassword: `${user}/forgotPassword`,
  resetPassword: `${user}/resetPassword`,
  resendOtp: `${user}/resendOtp`,
  getProfileUser: `${user}/getProfileUser`,
  changePassword: `${user}/changePassword`,

  //USER AUTH 2FA
  verify2Falogin: `${user}/verify2Falogin`,
  editEmail2FA: `${user}/editEmail2FA`,
  verify2FAOTP: `${user}/verify2FAOTP`,
  enableEmail2FAGoogle: `${user}/enableEmail2FAGoogle`,
  verifyTwoFactorGoogle: `${user}/verifyTwoFactorGoogle`,

  // STATIC
  faqList: `${Static}/faqList`,
  viewStaticContent: `${Static}/viewStaticContent`,

  // socket API
};
export default ApiConfig;
