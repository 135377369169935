import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  IconButton,
  Paper,
} from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Twitter, Telegram } from "@material-ui/icons";
import { BsFacebook, BsDiscord } from "react-icons/bs";
import { AiFillYoutube } from "react-icons/ai";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    padding: "40px 0",
    position: "relative",
    zIndex: "999",
    backgroundColor: "#1C1C1C",
    [theme.breakpoints.down("sm")]: {
      padding: "25px 0",
    },
    "& p": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px !important",
        maxWidth: "100% !important",
      },
    },
    "& h2": {
      color: "#fff",
      fontSize: "25px !important",
      fontWeight: "500",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px !important",
      },
    },
    "& .footerlogo": {
      width: "auto",
      maxWidth: "154px",
      height: "43px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100px",
      },
    },
  },
  menuButton: {
    fontSize: "17px",
    color: "#fff",
    cursor: "pointer",
    fontWeight: "300",
    display: "block",
    marginTop: "18px",
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      lineHeight: "15px",
      marginTop: "14px",
    },
    "&.active": {
      color: "#AE42E0",
    },
    "&:hover": {
      color: "#AE42E0",
    },
    "&[tabIndex]:focus": {
      color: "#fff",
    },
  },
  icons: {
    maxWidth: "250px",
    [theme.breakpoints.only("xs")]: {
      maxWidth: "430px",
    },
    "& svg": {
      fill: "currentColor",
      width: "1em",
      height: "1em",
      display: "inline-block",
      fontSize: "1.5rem",

      "&:hover": {
        color: "#E59446",
      },
    },
  },
  socialIcons: {
    padding: "0px",
    marginRight: "11px",
    "& svg": {
      paddingRight: "10px",
      fontSize: "23px",
      color: "#fff",
      transition: "transform 0.3s ease",
      "&:hover": {
        color: "#AE42E0",
        transform: "scale(1.3)",
      },
    },
  },
  subscribetext: {
    "& p": {
      color: "#FFF",
      margin: "29px 0",
      maxWidth: "274px",
      lineHeight: "22px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        margin: "20px 0 0px",
      },
    },
  },
}));

export default function Liquidity() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.footerSection}>
        <Container>
          <Grid container spacing={2} style={{ alignItem: "center" }}>
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <Box className={classes.subscribetext}>
                <img
                  src="./images/footer_logo1.png"
                  alt="Logo"
                  width="100%"
                  className="footerlogo"
                />
                <Typography
                  variant="body2"
                  style={{
                    color: "#fff",
                    textAlign: "left",
                    marginTop: "12px",
                  }}
                >
                  BlackswanFx
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={6}>
              <Box>
                <Typography variant="h2">Features</Typography>
              </Box>
              <p
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="about"
                onClick={() => {
                  history.push({
                    pathname: "/market",
                  });
                }}
              >
                Multi-Currency Support
              </p>
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={6}>
              <Typography variant="h2">Company</Typography>
              <p
                className={classes.menuButton}
                onClick={() => history.push("/about")}
              >
                About Us
              </p>

              {/* <p
                className={classes.menuButton}
                onClick={() => {
                  history.push({
                    pathname: "/contact-us",
                  });
                }}
              >
                Contact Us
              </p> */}
            </Grid>
            <Grid item lg={3} md={4} sm={4} xs={12}>
              <Typography variant="h2">Follow us on social media</Typography>
              <Box className={classes.icons} mt={2}>
                <IconButton
                  target="_blank"
                  href="https://www.facebook.com/blackswanfxofficial"
                  className={classes.socialIcons}
                >
                  <BsFacebook />
                </IconButton>
                <IconButton
                  target="_blank"
                  href="https://www.instagram.com/blackswanfxofficial/"
                  className={classes.socialIcons}
                >
                  <InstagramIcon />
                </IconButton>

                {/* <IconButton
                  target="_blank"
                  href="https://www.telegram.com/"
                  className={classes.socialIcons}
                >
                  <Telegram />
                </IconButton>

                <IconButton
                  target="_blank"
                  href="https://www.telegram.com/"
                  className={classes.socialIcons}
                >
                  <BsDiscord />
                </IconButton>

                <IconButton
                  target="_blank"
                  href="https://www.telegram.com/"
                  className={classes.socialIcons}
                >
                  <AiFillYoutube />
                </IconButton> */}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
}
